'use client';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useTranslations } from 'next-intl';
import React, { useContext } from 'react';

import { MenuItemGroupType, MenuItemType } from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { cn } from '@/utils/tailwind';

import { maxItemsForSingleLayer } from './constants';
import styles from './header-navigation-v2.module.css';
import {
  NavigationMenuItem,
  NavigationMenuItemGtmProps,
} from './navigation-menu-item';
import { customNavigation } from './utils';

type SingleMenuLayerType = {
  groupType: MenuItemGroupType;
  title: string;
  items: MenuItemType[];
  enabled: boolean;
};

export function HeaderNavigationV2(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      features: RewardsCentralConfiguration['application']['features'];
      navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
    },
) {
  const {
    headerLinks,
    earnHeaderLinks,
    redeemHeaderLinks,
    accountHeaderLinks,
  } = useHeaderLinks(props.features, props.navigationConfiguration);

  const t = useTranslations('headerNavigationV2');

  const redeemRewardsText = t('redeemRewards');
  const earnMoreText = t('earnMore');
  const myAccountText = t('myAccount');

  const isMultiLayer = headerLinks.length > maxItemsForSingleLayer;

  const navigationLayers: SingleMenuLayerType[] = [
    {
      groupType: 'redeem' as const,
      title: redeemRewardsText,
      items: redeemHeaderLinks,
      enabled: isMultiLayer && redeemHeaderLinks.length > 0,
    },
    {
      groupType: 'earn' as const,
      title: earnMoreText,
      items: earnHeaderLinks,
      enabled: isMultiLayer && earnHeaderLinks.length > 0,
    },
    {
      groupType: 'redeem' as const,
      title: redeemRewardsText,
      items: headerLinks,
      enabled: !isMultiLayer && headerLinks.length > 0,
    },
    {
      groupType: 'myAccount' as const,
      title: myAccountText,
      items: accountHeaderLinks,
      enabled: true,
    },
  ].filter((item: SingleMenuLayerType) => item.enabled);

  return <DesktopNavigation {...props} navigationLayers={navigationLayers} />;
}

function DesktopNavigation(
  props: RewardsCentralConfiguration['application']['components']['header'] &
    RewardsCentralConfiguration['application']['theme'] & {
      navigationLayers: SingleMenuLayerType[];
    },
) {
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const [focusOn, setFocusOn] = React.useState<string>('');
  const tenantHubConfig = useTenantHubConfig();
  const backgroundColor =
    tenantHubConfig?.header_color ?? props.backgroundColor;
  const tenantHubHeaderFontColor =
    tenantHubConfig?.header_font_color ?? props.color;

  return (
    <NavigationMenu.Root
      className="h-full"
      onValueChange={(value) => {
        setFocusOn(value);
      }}
    >
      <NavigationMenu.List className="h-auto content-center lg:flex lg:gap-x-6">
        {props.navigationLayers.map((layer, id) => {
          return (
            <NavigationMenu.Item
              key={layer.groupType}
              value={`${layer.groupType}-menu-group`}
              className="flex justify-center"
              data-state={id === 0 ? 'open' : undefined}
              data-testid={`menu-group-${layer.groupType}`}
            >
              <NavigationMenu.Trigger
                className={cn('text-header', {
                  'opacity-25':
                    focusOn !== `${layer.groupType}-menu-group` &&
                    focusOn !== '',
                })}
              >
                {layer.title}
              </NavigationMenu.Trigger>

              <NavigationMenu.Content
                className={cn(
                  'absolute left-0 top-full w-full py-8',
                  styles.NavigationMenuContent,
                )}
                style={{ backgroundColor }}
                data-testid={`menu-group-${layer.groupType}-content`}
              >
                <NavigationMenu.Sub className="mx-auto container-responsive">
                  <ul className="grid grid-cols-4 gap-x-12 gap-y-10">
                    {layer.items.map((item) => (
                      <div
                        key={item.itemType}
                        className={cn(
                          '-mx-4 -my-3 rounded px-4 py-3',
                          'active:bg-[var(--rc-menu-item--active-bg-color-v2)]',
                          'hover:bg-[var(--rc-menu-item--hover-bg-color-v2)]',
                          'focus:border-2 focus:border-shakespeare-300',
                        )}
                      >
                        <NavigationMenuItem
                          onClick={(
                            event: React.MouseEvent<HTMLAnchorElement>,
                          ) => {
                            customNavigation(item, event, setShowRedirectModal);
                          }}
                          data-gtm={JSON.stringify({
                            event: 'homepage_v2_navigation_menu_click',
                            group_0: item.groupType,
                            type_0: item.itemType,
                          } satisfies NavigationMenuItemGtmProps)}
                          className={cn(
                            'gtm:homepage_v2_navigation_menu_item',
                            'px-0 py-0',
                          )}
                          data-testid={`menu-item-${item.itemType}`}
                          menuItem={item}
                          titleClassName="text-base"
                          descriptionClassName="text-sm"
                          href={item.href}
                          displayMode="full"
                          style={{
                            ...(tenantHubHeaderFontColor
                              ? { color: tenantHubHeaderFontColor }
                              : {}),
                          }}
                        />
                      </div>
                    ))}
                  </ul>
                </NavigationMenu.Sub>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
          );
        })}
      </NavigationMenu.List>
      <NavigationMenu.Viewport />
    </NavigationMenu.Root>
  );
}
